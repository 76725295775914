<template>
    <b-container fluid="">
        <b-card no-body class="mb-1 p-1">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="load">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-table
            :items="items"
            :fields="fields"
            @row-dblclicked="toForm"
            hover
            :responsive="true"
            head-variant="dark"
        >
            <template #cell(actions)="row">
                <b-button
                    @click="toForm(row.item)"
                    size="sm"
                    variant="warning"
                >
                    <b-icon-pencil-square/>
                </b-button>
            </template>
        </b-table>
    </b-container>
</template>

<script>
import {AreaService} from '@services';

export default {
    name: 'Index',
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'name',
                    label: 'Наименование',
                },
                {
                    key: 'limit',
                    label: 'Лимит в лотках',
                },
                {
                    key: 'actions',
                    label: 'Действия',
                    tdClass: 'col-1',
                }
            ],
            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 25,
            },
        }
    },
    methods: {
        load() {
            AreaService.getWithPaginate().then(({data, meta}) => {
                this.items = data;
                this.meta = meta;
            });
        },
        toForm({id}) {
            this.$router.push({name: 'AreaEdit', params: {id}});
        }
    },
    mounted() {
        this.load();
    }
}
</script>
